import React from "react";
import BeLaunchifiedComponent from "../components/BeLaunchifiedPage/BeLaunchified/BeLaunchified";

const BeLaunchified = () => {
  return (
    <div>
      <BeLaunchifiedComponent />
    </div>
  );
};

export default BeLaunchified;
