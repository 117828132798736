import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ResourcesCaseStudy.css";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function ResourcesCaseStudy() {
  const history = useHistory();
 
 
  return (
    <div>
      <Container fluid className="resources-case-study-launch-data">
 
        <Row>
          <Col className="right-column">
            <span className="resources-case-study">Case Study</span>
            <span className="resource-study-enrol">
              Our clients are the companies and services that make the world go round. They build community, streamline public transit, respond to emergencies, and teach with AI.
            </span>
            <p className="resource-study-paraS">
              We’re your one-stop-digital-shop for product growth, increased engagement, and better business.
            </p>
            <button type="button" className="btnExplore btn btn-light" onClick={()=> history.push("/capabilities")}>
              See yourself
            </button>
          </Col>
        </Row>
 
      </Container>
    </div>
  );
}
 
export default ResourcesCaseStudy;