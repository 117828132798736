import React from "react";
import "./ResourcesCardView.css";
import data from "./ResourcesCardView.json";
import ResourcesCard from "./ResourcesCard";
import { useTranslation } from "react-i18next";

const ResourcesCardView = () => {
  const { t } = useTranslation("common");

  return (
    <div>
      <div className="container">
        <div className="row">
          {data.container1.data.map((card, i) => (
            <div className="col-lg-6 md-4 py-4" key={i}>
              <ResourcesCard
                imageUrl={card.image}
                name={card.name}
                hyperLink={card.hyperLink}
              />
            </div>
          ))}
        </div>
        <div className="row">
          {data.container2.data.map((card, i) => (
            <div className="col-lg-6 md-4 py-4" key={i}>
              <ResourcesCard
                imageUrl={card.image}
                name={card.name}
                hyperLink={card.hyperLink}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResourcesCardView;
