import React from "react";
import "./AgileWaysOfWorking.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useTranslation } from "react-i18next";

const AgileWaysOfWorking = () => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <div className="technologies">Technologies</div>
      <Row>
        <Col>
          <div className="agile-ways-of-working">Agile Ways of Working</div>

          <div className="agile-quality-engineering-text">
            Agile quality engineering is the foundation for digital innovation
            It enables you to deliver better products faster and more
            effectively than your competition. And — while agile is now the
            industry standard — its smart, scalable implementation across all
            areas of your business that sets you apart. Your first step is a
            thorough outside-in analysis of where your gaps lie and how to fill
            them.
          </div>
        </Col>
        <Row>
          <Col>
            <div className="agile-way-of-working-image">
              <img src="assets/images/agilewayofworking.png" />
            </div>
          </Col>
        </Row>
      </Row>

      <Row>
        <Col>
          <img src="assets/images/azure.png" className="dispaly-logo" />
        </Col>
        <Col>
          <img src="assets/images/angular.png" className="dispaly-logo" />
        </Col>
        <Col>
          <img src="assets/images/android.png" className="dispaly-logo" />
        </Col>
        <Col>
          <img src="assets/images/ios.png" className="dispaly-logo" />
        </Col>
        <Col>
          <img src="assets/images/flutterlogo.png" className="dispaly-logo" />
        </Col>
      </Row>
      <div className="agile-way-bottom-verticle-line"></div>
    </Container>
  );
};

export default AgileWaysOfWorking;
