import React from "react";
import "./CapabilitiesView.css";
import { useTranslation } from "react-i18next";
import ListItems from "./ListItems";
import { Row, Col } from "react-bootstrap";

function CapabilitiesView() {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="capabilities-container">
        <div className="capabilities">Capabilities</div>
        <div className="continuous-teams-global-coverage">
          Continuous teams, global coverage
        </div>

        <ListItems />
      </div>
    </>
  );
}

export default CapabilitiesView;
