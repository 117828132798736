
import  React, { useEffect, useRef, useState } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "./HomeCarousel.css";

function HomeCarousel() {
//   const carouselRef = useRef(null);
//  useEffect(() => {
//    const carouselElement = carouselRef.current;
//    const prevControl = carouselElement.querySelector(".carousel-control-prev");
//    const nextControl = carouselElement.querySelector(".carousel-control-next");
//    const updateArrows = () => {
//      const activeSlide = carouselElement.querySelector(".carousel-item.active");
//      const isFirstSlide = !activeSlide.previousElementSibling;
//      const isLastSlide = !activeSlide.nextElementSibling;
//      // Disable left arrow on the first slide
//      if (isFirstSlide) {
//        prevControl.classList.add("disabled");
//      } else {
//        prevControl.classList.remove("disabled");
//      }
//      // Optionally disable right arrow on the last slide
//      if (isLastSlide) {
//        nextControl.classList.add("disabled");
//      } else {
//        nextControl.classList.remove("disabled");
//      }
//    };
//    // Attach the Bootstrap `slid` event
//    const handleSlide = () => {
//      updateArrows();
//    };
//    carouselElement.addEventListener("slid.bs.carousel", handleSlide);
//    // Initial arrow state
//    updateArrows();
//    // Cleanup event listener
//    return () => {
//      carouselElement.removeEventListener("slid.bs.carousel", handleSlide);
//    };
//  }, []);
const [activeIndex, setActiveIndex]  = useState(0);

const handleSelect = (selectedIndex) => {
  setActiveIndex(selectedIndex)
};
  return (
    <div>
      <Container fluid className="carousel-container">
        {/* <div ref={carouselRef}> */}
        <Carousel  activeIndex= {activeIndex} onSelect={handleSelect} interval={null} indicators={true} fade>
          <Carousel.Item>
            <Row className="align-items-center">
              <Col md={6} className="p-4">
                <div className="text-container">
                  <h1 className="launch-aca">Launch Academy</h1>
                  <h2 className="launch-bold">
                    Launch <br></br> is for the <br></br>
                    <h1 className="bold-css">Bold</h1>
                  </h2>
                  <div className="mission mt-4">
                    <p>
                      <span className="fw"></span>
                      <p className="video-para">
                        Join us and create digital <br></br>experiences that
                        move millions
                      </p>
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <video
                  className="media-video"
                  src="./HomePageImages/MainVideoHome.mp4"
                  controls={true}
                />
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <div className="launch-background">
              <div className="vector-line1"></div>
              <div className="container-fluid">
                <div className="d-flex flex-column flex-md-row align-items-start justify-content-between">
                  <div className="text-container">
                    <h1 className="llaunch-academy">Launch Academy</h1>
                    <h2 className="learn-earn">
                      Learn & <br></br>
                      <div className="earn-css">Earn</div>
                    </h2>
                    <h2 className="belaunchified">be Launchified.</h2>
                  </div>

                  <div className="image-container">
                    <img
                      src="4pillar.png"
                      alt="pillar"
                      className="image-pillar"
                    />
                  </div>
                </div>
                <div className="mission mt-4">
                  <p className="paraMission">
                    <span className="fw-bold">Mission:</span> We exist to help
                    you strategize, ship, and <br></br>scale provocative digital
                    experiences that connect with<br></br> your customers and
                    drive growth.
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        {/* </div> */}
      </Container>
    </div>
  );
}

export default HomeCarousel;
