import React from "react";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./AimEngageGrow.css";
import { motion } from "framer-motion";

function AimEngageGrow() {
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.9 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.9 } },
  };

  return (
    <div id="aimengagegrowID">
      <div>
        <br></br>
        <Row>
          <div className="container-fluid"></div>
          <motion.div
            className="row align-items-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
          >
            <motion.div className="col-md-6" variants={textVariants}>
              <h3 className="textN">How we do it</h3>

              <p className="paraN">
                We deliver exceptional software with the speed of a startup and
                the systems focus of a mature business.
              </p>
            </motion.div>
            <motion.div
              className="col-md-6 text-center"
              variants={textVariants}
            >
              <img
                src="./HomePageImages/aimgroweng.svg"
                style={{ width: "60%" }}
              ></img>
            </motion.div>
          </motion.div>
        </Row>

        <br></br>
        <Row>
          <div className="container-fluid"></div>

          <motion.div
            className="row align-items-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
          >
            <motion.div
              className="col-md-6 text-center"
              variants={textVariants}
            >
              <img
                src="./HomePageImages/aim.svg"
                style={{ width: "60%" }}
                className="img-fluid"
              ></img>
            </motion.div>

            <motion.div className="col-md-6" variants={textVariants}>
              <h3 className="textN">
                {" "}
                <img src="./HomePageImages/aim1icon.svg "></img> Aim
              </h3>

              <p className="paraN">
                Invent what's next by<br></br> understanding what your audience
                needs.
              </p>
            </motion.div>
          </motion.div>
        </Row>

        <br></br>
        <Row>
          <div className="container-fluid"></div>
          <motion.div
            className="row align-items-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
          >
            <motion.div className="col-md-6" variants={textVariants}>
              <h3 className="textN">
                {" "}
                <img src="./HomePageImages/EngageIcon.svg "></img> Engage
              </h3>

              <p className="paraN">Validate your product with yours users</p>
            </motion.div>
            <motion.div
              className="col-md-6 text-center"
              variants={textVariants}
            >
              <img
                src="./HomePageImages/engage.svg"
                style={{ width: "60%" }}
              ></img>
            </motion.div>
          </motion.div>
        </Row>

        <br></br>
        <Row>
          <div className="container-fluid"></div>

          <motion.div
            className="row align-items-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
          >
            <motion.div
              className="col-md-6 text-center"
              variants={textVariants}
            >
              <img
                src="./HomePageImages/grow.svg"
                style={{ width: "60%" }}
                className="img-fluid"
              ></img>
            </motion.div>

            <motion.div className="col-md-6" variants={textVariants}>
              <h3 className="textN">
                {" "}
                <img src="./HomePageImages/GrowIcon.svg "></img> Grow
              </h3>

              <p className="paraN">Increase trust and expand market share</p>
            </motion.div>
          </motion.div>
        </Row>

        <br></br>
      </div>
    </div>
  );
}

export default AimEngageGrow;
