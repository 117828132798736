import React from "react";
import "./ServiceSpectrum.css";
import { Container, Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const ServiceSpectrum = () => {
  const { t } = useTranslation("common");

  return (
    <Container className="main-content">
      <Row>
        <div className="arrow-right">
          <a href="#">
            <img src="assets/images/arrowLeft.png" />
          </a>
        </div>
      </Row>
      <Row>
        <Col className="service-spectrum">Service Spectrum</Col>
      </Row>
      <Row>
        <div className="main-cap-div">
          <div className="outshine-the-competition-text">
            Outshine the competition with spectacular digital products-and do it
            at scale
          </div>
          <div className="col2">
            <a href="#">
              <img className="imageSp" src="assets/images/goasLogo.png" />
            </a>
          </div>
        </div>
      </Row>
      <Row>
        <div className="were-your-one-stop-digital-text">
          We’re your one-stop-digital-shop for product growth, increased
          engagement, and better business.
        </div>
      </Row>
    </Container>
  );
};

export default ServiceSpectrum;
