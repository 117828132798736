import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./HowToBeLaunchified.css";
import { useHistory } from "react-router-dom";



function HowToBeLaunchified() {
  const history = useHistory();
  return (
    <div className="how-section">
      <img
       className="manImage"
       src="./HomePageImages/Manimage.png"
       alt="pillar"
       />
      <Container fluid className="how-to-be-launch-data">
        <Row>
          <Col className="right-column">
            <span className="how-to-be-launchified">How to be Launchified</span>
            <span className="enrol col-xl-8 col-lg-7 col-md-6 col-sm-5">
              Enroll In The Specially Crafted Courses And Get </span>
              <span className="Launchi"> Launchified.</span>
            
            <p className="paraL col-xl-8 col-lg-7 col-md-6 col-sm-5">
              At Launch Academy, we invite our employees and clients to join
              specially designed courses that align with our launch mission. By
              successfully completing the courses, you’ll earn a gamified
              certificate and become a proud member of our launchified team.
            </p>
            <button type="button" className="btnExplore btn btn-light" onClick={()=> history.push('/belaunchified')}>
              Let's explore
            </button>
          </Col>
    
        </Row>
      </Container>
    </div>
  );
}
export default HowToBeLaunchified;
 