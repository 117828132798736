import Container from "react-bootstrap/Container";
import "./LeaderShip.css";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import data from "./LeaderShip.json";

function LaunchDetails() {
  return (
    <div>
      <div class="Vector-1"></div>
      <Container fluid className="launchDetails">
        <Row>
          <div className="launchified-academy">Launchified</div>
        </Row>
        <Row>
          <Col>
            <div class="Practice-Leadership col">Practice Leadership</div>
          </Col>
          <Col>
            <div className="line"></div>
          </Col>
          <Col>
            <div class="The-Launch-Experience col-5">
              The Launch Experience Design team is focused on building
              exceptional experiences for our clients, their employees, and
              their customers. <br></br>
              <p></p>We work strategically with clients through user research,
              workshops, UX and UI design, prototyping, front-end development,
              and analytics, to create ideal experiences for users that also
              meet business objectives.
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Container className="leadersContainer">
          <Row>
            {data.container1.data.map((item) => (
              <div className="col" key={item.id}>
                <Card
                  className=" mx-2 rounded  bg-light"
                  style={{ width: "20rem", height: "18rem" }}
                >
                  <Card.Img variant="top" src={item.image} fluid />
                  <Card.Body>
                    <span class="Soares-Tammy">{item.name}</span>
                    <span class="PRODUCT-LAUNCH-PRESIDENT">
                      {item.designation}
                    </span>
                    <img
                      className="LinkedInImg"
                      src={item.linkedinimage}
                      alt="profile_president"
                    ></img>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>
        </Container>
      </div>
      <div class="Vector-10"></div>
      <div>
        <Container fluid className="launchDetails2">
          <Row>
            <div className="launchified-academy1">Launchified</div>
          </Row>
          <Row>
            <Col>
              <div class="Exp-Leadership col">Experience Designers</div>
            </Col>
            <Col>
              <div className="line2"></div>
            </Col>

            <Col>
              <div class="The-Launch-ExperienceP col-5">
                Our Experience Designers fluctuate between a variety of user
                experience roles. They can conduct user research, facilitate
                workshops, create UX designs and prototypes, design creative
                mockups, plan a design system, or prioritize a strategic roadmap
                for a client.<br></br><p></p> Experience Designers are able to take a look at
                the client problem and assess the true root cause, keeping in
                mind business objectives, to develop the framework for the ideal
                solution to the client’s problem.
              </div>
            </Col>
          </Row>
        </Container>
        <div>
          <Container className="leadersContainer2">
            <Row className="exp-des-cards">
              {data.container3.data.map((item) => (
                <div className="col" key={item.id}>
                  <Card
                    className=" mx-2 rounded  bg-light"
                    style={{ width: "20rem", height: "18rem" }}
                  >
                    <Card.Img
                      variant="top"
                      src={item.image}
                      // fluid
                      // style={{ objectFit:'cover' , height: '200px'}}
                    />
                    <Card.Body>
                      <span class="Soares-Tammy">{item.name}</span>
                      <span class="PRODUCT-LAUNCH-PRESIDENT">
                        {item.designation}
                      </span>
                      <img
                        className="LinkedInImg"
                        src={item.linkedinimage}
                        alt="profilepic"
                      ></img>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Row>
          </Container>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default LaunchDetails;
