import React from "react";
import CompletedCourses from "../components/CompletedCourses/CompletedCourses";
import { motion } from "framer-motion";

function Certifications() {
  return (
    <div>
      <motion.div
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.1 }}
      >
        <CompletedCourses />
      </motion.div>
    </div>
  );
}
export default Certifications;
