import React from "react";

const baseUrl = "https://catalys.portal.nttdataservices.com";
let courseCatalogItemList = [];
const newImageSrc = "./assets/images/userimg.png";

const fetchUserData = async () => {
  const userPage = await fetch(`${baseUrl}/`);
  const domContentText = await userPage.text();
  const parser = new DOMParser();
  const contentHtml = parser.parseFromString(domContentText, "text/html");
  console.log("------------------ User Details ---------------");

  // Hacks for image quality
  //f1 = 100x100
  //f2 = 35x35
  //f3 = 512x512

  // //get user profile link
  const profilePageLink = contentHtml
    .getElementById("carousel-item-main")
    .childNodes.item(1).href;
  const profilePageContent = await fetch(profilePageLink);
  const profilePageContentText = await profilePageContent.text();
  const profilePageContentHtml = parser.parseFromString(
    profilePageContentText,
    "text/html"
  );

  const profileImage = contentHtml
    .getElementsByClassName("avatar")
    .item(0)
    .childNodes.item(1).nodeName;
  const profileImageSrc =
    profileImage === "IMG"
      ? contentHtml.getElementsByClassName("avatar").item(0).childNodes.item(1)
          .src
      : newImageSrc;

  //User name
  const username = profilePageContentHtml
    .getElementsByClassName("header-heading")
    .item(0).textContent;

  console.log("User Name: " + username);
  console.log("-------------------------------------------------");
  let userDetails = [username, profileImageSrc];
  console.log(userDetails);

  return userDetails;
};
const fetchCourseDetails = async (courseId) => {
  courseCatalogItemList = [];
  const res = await fetch(`${baseUrl}/course/catalogue.php?id=1816`, {
    method: "POST",
    headers: { "Content-Type": "application/xml" },
    body: '<ChildrenRequest version="1"><Attribute name="code" value="1816"/></ChildrenRequest>',
  });
  const response = await res.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(response, "text/xml");
  const courseList = parseCatalogString(
    xmlDoc.querySelector("Node").getAttribute("text")
  );
  console.log("catalog dataa>>>>>>>>>>>>", courseList);
  return courseList;
};
const parseCatalogString = (catlogDoc) => {
  let courseCatalogItemList = [];
  const parser = new DOMParser();
  var webContent = parser.parseFromString(catlogDoc, "text/html");
  const table = webContent.getElementsByClassName("tablea").item(0);
  console.log(
    "--------------- GenAI Certifications Courses  ------------------"
  );
  for (var i = 1, row; (row = table.rows[i]); i++) {
    console.log(
      "==> Title: ".concat(
        row.cells[1].textContent,
        ", Link: ",
        row.cells[1].childNodes.item(0).href,
        ", Duration: ",
        row.cells[2].textContent
      )
    );
    courseCatalogItemList.push({
      title: row.cells[1].textContent,
      link: row.cells[1].childNodes.item(0).href,
      duration: row.cells[2].textContent,
    });
  }
  return courseCatalogItemList;
};
const fetchUserBadges = async () => {
  const url = `${baseUrl}/local/report/userbadge.php?option=1`; //for current year
  //const url="https://catalys.portal.nttdataservices.com/local/report/userbadge.php"; //for all years
  let badgeList = [];
  var content = await fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error("Error fetching data");
    }
    return response.text();
  });
  const parser = new DOMParser();
  var webContent = parser.parseFromString(content, "text/html");
  console.log("------------------ User Badges ---------------");
  const userBadges = webContent
    .getElementById("issued-badge-table")
    .getElementsByTagName("li");
  console.log(userBadges);
  console.log("Total Badges: " + userBadges.length);
  for (var i = 0; i < userBadges.length; i++) {
    //console.log('Name: '+userBadges.item(i).textContent+', Image: '+userBadges.item(0).getElementsByTagName('img').item(0).src);
    badgeList.push({
      key: i,
      name: userBadges.item(i).textContent,
      image: userBadges.item(i).getElementsByTagName("img").item(0).src,
    });
  }
  console.log(badgeList);
  return badgeList;
};
const fetchUserCertificates = async () => {
  const url = `${baseUrl}/local/report/certificates.php`;
  var certList = [];
  var content = await fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error("Error fetching data");
    }
    return response.text();
  });
  const parser = new DOMParser();
  var webContent = parser.parseFromString(content, "text/html");
  const tables = webContent.getElementsByTagName("table");
  //console.log(tables);
  for (var i = 1; i < tables.length; i++) {
    var courseName = tables
      .item(i)
      .getElementsByTagName("td")
      .item(0).textContent;
    var certLoc = tables
      .item(2)
      .getElementsByTagName("td")
      .item(1)
      .getElementsByTagName("a")
      .item(0)
      .getAttribute("href");
    if (courseName !== "Certificate Name") {
      //console.log('Course: '+ courseName +', Certificate link: '+ certLoc);
      certList.push({
        key: i,
        courseName: courseName.replaceAll("\n", ""),
        certLocation: certLoc,
      });
    }
  }
  console.log(
    "Total Certificates>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>: " +
      certList.length
  );
  console.log(certList);
  return certList;
};

export { fetchUserData };
export { fetchCourseDetails };
export { fetchUserBadges };
export { fetchUserCertificates };
