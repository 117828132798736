import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./BeLaunchified.css";
const BeLaunchifiedCard = ({ title, link, duration }) => {
  const handleClick = () => {
    window.open(link, "_blank");
  };

  return (
    <Container className="card">
      <div onClick={handleClick}>
        <Row>
          <Col>
            {" "}
            <img
              src="./assets/images/AI2Image.png"
              className="card-img"
              alt="imgurl"
            />
          </Col>
          <Col>
            <h3 className="mb-4 gen-AI-101-certification">{title}</h3>
            <h3 className="mb-4 knowledge-check-gen-AI">
              Knowledge Check on Introduction to Generative AI
            </h3>
          </Col>
          <Col>
            <h3>
              <img
                src="./assets/images/cardStar.png"
                className="crad-star-align"
                alt="cardstarurl"
              />
            </h3>
          </Col>
        </Row>
        <Row className="p-1 g-col-1 border-top">
          <Col>
            <div className="intended-audience-everyone">
              Intended Audience
              <div className="text-style-1">Everyone</div>
            </div>
          </Col>
          <Col>
            <div className="duration-150-minutes">
              <div className="text-style-1">Duration</div>
              {duration}
            </div>
          </Col>

          <Col>
            <Col>
              <button type="button" className="enroll btn btn-dark">
                Enroll
              </button>
            </Col>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default BeLaunchifiedCard;
