import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./WhyLaunchified2.css";
import { HashLink } from "react-router-hash-link";

const WhyLaunchified2 = () => {
  return (
    <div className="grow-container container-fluid">
      <div className="row justify-content-center align-items-center text-center text-lg-start">
        <div className="col-lg-11">
          <h6 className="why-launchified2">Why Launchified</h6>
          <h1 className="main-heading">
            Aim high, engage fully,
            <br />
            <span>Grow Beyond Limits.</span>
          </h1>
          <p className="description">
            At Launch, our focus is on driving successful product launches
            through a proven model designed to help our clients thrive. We want
            you to understand how our <b>Aim, Engage, and Grow model</b>{" "}
            supports our clients at every step through our specially{" "}
            <b>crafted & designed</b> Launchified courses.
          </p>
         
            <HashLink  className="explore-button btn btn-dark" smooth to="#aimengagegrowID">
              {" "}
              Explore aim, engage, grow{" "}
            </HashLink>
     
        </div>
      </div>
    </div>
  );
};
export default WhyLaunchified2;
