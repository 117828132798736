import React from "react";
import ServiceSpectrum from "../components/CapabilitiesPage/ServiceSpectrum/ServiceSpectrum";
import CapabilitiesView from "../components/CapabilitiesPage/CapabilitiesView/CapabilitiesView";
import AgileWaysOfWorking from "../components/CapabilitiesPage/AgileWaysOfWorking/AgileWaysOfWorking";
import { motion } from "framer-motion";

const Capabilities = () => {
  return (
    <div>
      <motion.div
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
        <div>
          <ServiceSpectrum />
        </div>
        <div>
          <CapabilitiesView />
        </div>
        <div>
          <AgileWaysOfWorking />
        </div>
      </motion.div>
    </div>
  );
};

export default Capabilities;
