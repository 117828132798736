import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from "react-bootstrap/Dropdown";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { fetchUserData } from "../../Providers/CatalogDataService";
import { fetchUserBadges } from "../../Providers/CatalogDataService";
import React, { useState, useEffect } from "react";

function NavbarData() {
  const [userData, setUserData] = useState([]);
  const [badges, setBadges] = useState([]);
 
  useEffect(() => {
    fetchUserData().then((res) => setUserData(res));
  }, []);
 
  useEffect(() => {
    fetchUserBadges().then((res) => setBadges(res));
  }, []);
 
  return (
    <Navbar expand="lg" className="navbar-res">
      <Container>
        <Navbar.Brand href="index.html">
          <img
            className="launch-logo"
            src="./HomePageImages/launch_logo.png"
            alt="launch_logo"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="index.html">
              <img
                className="home_icon"
                src="./HomePageImages/homeicon.png"
                alt="home_icon"
              ></img>
            </Nav.Link>
 
            <NavLink to="/belaunchified" className="launchified_data">
              Be Launchified
            </NavLink>
            <NavLink to="/capabilities" className="launchified_data">
              Capabilities
            </NavLink>
            <NavLink className="launchified_data" to="/resources">
              Resources
            </NavLink>
 
            <Nav.Link className="user_data">Hi, {userData[0]}</Nav.Link>
 
            <Dropdown>
              <Dropdown.Toggle as="span" className="arrow">
                <img
                  className="arrow arrowicon"
                  src="./HomePageImages/arrow.png"
                  alt="arrow_icon"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-card">
                <div className="dropdown-header-black">
                  <div className="profile-pic-container">
                    <img
                      src={userData[1]}
                      alt="profile"
                      className="profile-pic-header"
                    />
                  </div>
                  <img
                    src="./HomePageImages/MainiconLaunch.png"
                    alt="Logo"
                    className="header-logo"
                  />
                </div>
                <div className="user-info-section">
                  <h3 className="user-name-header">{userData[0]}</h3>
                  <div className="info-layout">
                    <div className="user-role-section">
                      <p className="user-role-title">Digital Experience</p>
                      <p className="user-role-subtitle">Sr. Designer</p>
                    </div>
                    <div className="vertical-vector"></div>
                    <div className="performance-section">
                      <p className="performance-title">2024 Performance</p>
                      <p className="performance-value">Highly Successful</p>
                    </div>
                    <div className="score-section">
                      <span className="score-big">13</span>
                      <span className="score-divider">/</span>
                      <span className="score-small">50</span>
                    </div>
                  </div>
                  <div className="horizontal-vector"></div>
                </div>
                <div className="dropdown-content">
                  <div className="badges-section">
                    <div className="menu-options">
                      <i className="fas fa-graduation-cap"></i> Badges
                    </div>
                    <div className="badges-container">
                      {badges.map((badges) => (
                        <img
                          key={badges.key}
                          src={badges.image}
                          alt={badges.name}
                        ></img>
                      ))}
                    </div>
                  </div>
 
                  <div className="horizontal-vector"></div>
                  <div className="menu-options">
                    <a
                      href="/certifications#/certifications"
                      className="menu-link"
                    >
                      <i className="fas fa-tasks"></i> Completed Courses
                    </a>
                   
                    <div className="horizontal-vector"></div>
                 
                    <i className="fas fa-sign-out"></i> Logout
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
 
            <Nav.Link className="arrow">
              <img
                className="nttdata_icon"
                src="./HomePageImages/nttdata.png"
                alt="nttdata_icon"
              />
            </Nav.Link>
            <div className="Vector-2"></div>
          </Nav>
        </Navbar.Collapse>
        <div className="vector_h"></div>
      </Container>
    </Navbar>
  );
}
export default NavbarData;