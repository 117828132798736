import React from "react";
import HowToBeLaunchified from "../components/HomePage/HowToBeLaunchified/HowToBeLaunchified";
import { motion } from "framer-motion";
import AimEngageGrow from "../components/AimEngageGrow/AimEngageGrow";
import HomeCarousel from "../components/HomePage/HomeCorousel/HomeCarousel";

import WhyLaunchified2 from "../components/HomePage/WhyLaunchified2/WhyLaunchified2";
function Home() {
  return (
    <div>
      <motion.div
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
        <div>
          <HomeCarousel />
        </div>
        <div>
          <WhyLaunchified2 />
        </div>
        <div>
          <AimEngageGrow />
        </div>
        <div>
          <HowToBeLaunchified />
        </div>
      </motion.div>
    </div>
  );
}

export default Home;
