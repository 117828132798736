import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import "./ResourcesCardView.css";
import { Link, useHistory } from "react-router-dom";
const ResourcesCard = ({ imageUrl, name, hyperLink }) => {
 const history = useHistory();
 const handleClick = () =>
 {
  if (hyperLink.startsWith('http')){
    window.open(hyperLink, '_blank');

  }else{
    history.push(hyperLink);
  }
 }
  return (
    <div
      className="resourcecard"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <img src={imageUrl} className="card-img-top" alt="image" />
      <div
        className="card-body square border"
        style={{
          backgroundColor: "#f5f5f5",
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <div className="card-text name">{name}</div>
        <div className="arrowright">
          <FontAwesomeIcon icon={faArrowRightLong} style={{ color: "#000" }} />
        </div>
      </div>
    </div>
  );
};

export default ResourcesCard;
