import React from "react";
import "./CapabilitiesView.css";
import data from "./CapabilitiesView.json";
import { useTranslation } from "react-i18next";
const ListItems = () => {
  const { t } = useTranslation("common");

  return (
    <div className="grid-container">
      <div className="grid-item ">
        Engineering
        <div className="engineering-text">
          <ul className="bullet-text-style">
            {data.container1.data.map((item) => (
              <div key={item.id}>
                <li>{item.bulletname}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <div className="grid-item vertical_line">
        Strategy & Innovation
        <div className="strategy-innovation-text">
          <ul className="bullet-text-style">
            {data.container2.data.map((item) => (
              <div key={item.id}>
                <li>{item.bulletname}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <div className="grid-item vertical_line">
        Experience Design
        <div className="experience-design-text">
          <ul className="bullet-text-style">
            {data.container3.data.map((item) => (
              <div key={item.id}>
                <li>{item.bulletname}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ListItems;
