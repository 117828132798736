import "./CompletedCourses.css";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import React, { useState, useEffect } from "react";
import { fetchUserCertificates } from "../../Providers/CatalogDataService";

const CompletedCourses = () => {
  const [certificates, setCertificates] = useState([]);
  useEffect(() => {
    fetchUserCertificates().then((res) => setCertificates(res));
  }, []);
  return (
    <div className="card-container">
      <div className="container">
        <div className="arrow-course">
          <a href="#">
            <img src="assets/images/arrowLeft.png" />
          </a>
        </div>
 
        <div className="row">
          <div className="col-2 text-start">
            <div className="launch-course">Launchified</div>
          </div>
          <div className="w-100"></div>
 
          <div className="col-9 text-start">
            <div className="completed-courses">Completed Courses</div>
          </div>
        </div>
        <br />
      </div>
      <br></br>
      <Container className="completedCourses-container">
        <Row>
          {certificates.map((certificates, key) => (
            <div className="col" key={key}>
              <Card
                className=" mx-0 rounded  bg-light"
                style={{ width: "20rem", height: "20rem" }}
              >
                <Card.Img
                  className="img-cert"
                  variant="top"
                  src="./assets/images/AI1Image.png"
                  fluid
                />
                <Card.Body>
                  <span class="courseTitle">
                    {certificates.courseName || []}
                  </span>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CompletedCourses;
