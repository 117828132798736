import React from "react";
import "./ResourcesLaunchified.css";
import { Container, Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const ResourcesLaunchified = () => {

  return (
    <Container className="main-content">
      <Row>
        <Col>
          <div className="arrow-right">
            <a href="#">
              <img src="assets/images/arrowLeft.png" />
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <div>
          <div className="launchified">
          Launchified
          </div>
        </div>
      </Row>
      <Row>
        <div>
          <div className="resources">
            Resources
          </div>
        </div>
      </Row>
      <Row>
        <div className="Were-your-one-stop-text-resource">
        We’re your one-stop-digital-shop for product growth, increased engagement, and better business.  
        </div>
      </Row>
    </Container>
  );
};

export default ResourcesLaunchified;
