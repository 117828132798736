import React from "react";
import LeaderShip from "../components/LeaderShipPage/LeaderShip/LeaderShip";
import { motion } from "framer-motion";

const LeaderShipScreen = () => (
  <div>
    <motion.div
      initial={{ y: "-100vh", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <LeaderShip />
    </motion.div>
  </div>
);

export default LeaderShipScreen;
