import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BeLaunchifiedCard from "./BeLaunchifiedCard";
import "./BeLaunchified.css";
import { fetchCourseDetails } from "../../../Providers/CatalogDataService";

function BeLaunchifiedComponent() {
  const { t } = useTranslation("common");
  const [userData, setUserData] = useState([]); // Original data from API
  const [filteredData, setFilteredData] = useState([]); //Data to display
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const handleSearchTxtClear = () => {
    setSearchQuery(""); //Clear the search input text field
  };
  useEffect(() => {
    // Fetch data from the API once when component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(
          fetchCourseDetails().then((res) => setUserData(res))
        ); //Replace with your API endpoint
        const result = await response.json();
        setUserData(result);
        setFilteredData(result); // Initially, show all the data
      } catch (error) {
        console.error("Error API fetching data-", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    // Filter data whenever search query changes
    const filtered = userData.filter(
      (item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.duration.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.link.toLowerCase().includes(searchQuery.toLowerCase)
      //item.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, userData]);
  return (
    <Container>
      <Row>
        <div className="arrow-right">
          <a href="#">
            <img src="assets/images/arrowLeft.png" />
          </a>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="launch-academy">Launch Academy</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="be-Launchified">Be Launchified</div>
        </Col>
      </Row>
      <Row>
        <div className="left-panel-div">
          <Row>
            <Col>
              <form>
                <label className="col-ms-6 py-4 fw-bold">Search Insights</label>
                <Row>
                  <Col>
                    <input
                      type="text"
                      value={searchQuery}
                      placeholder="Ex: Design or Tech"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Col>
                </Row>
              </form>
            </Col>
            <Col className="search-insights-clear-btn">
              <Button
                variant="btn btn-outline-primary"
                size="md"
                onClick={handleSearchTxtClear}
              >
                Clear
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <form>
                <label className="col-md-6 py-4 fw-bold">Industries</label>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary">
                      automotive
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      Business
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      energy
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      entertainment
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      environment
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      financial
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      healthcare
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      hospitality
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      insurance
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      logistics
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      manufacturing
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-6">
                    <Button variant="btn btn-outline-primary" size="md">
                      public sector
                    </Button>
                  </Col>
                  <Col className="col-md-4 py-8">
                    <Button variant="btn btn-outline-primary" size="md">
                      retail
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      science
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      sports
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      tech
                    </Button>
                  </Col>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      transportation
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 py-1">
                    <Button variant="btn btn-outline-primary" size="md">
                      travel
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col className="industries-clear-btn">
              <Button variant="btn btn-outline-primary" size="md">
                Clear
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <form>
                <label className="col-md-6 py-4 fw-bold">
                  Services
                  <Row>
                    <Col className="col-md-6 py-4">
                      <Button variant="btn btn-outline-primary" size="md">
                        ai/ml
                      </Button>
                    </Col>
                    <Col className="col-md-6 py-4">
                      <Button variant="btn btn-outline-primary" size="md">
                        design
                      </Button>
                    </Col>
                  </Row>
                </label>
              </form>
            </Col>
            <Col className="services-clear-btn">
              <Button variant="btn btn-outline-primary" size="md">
                Clear
              </Button>
            </Col>
          </Row>
        </div>

        <Row className="right-panel-main-div">
          <Row className="right-panel-top-div">
            <Col>
              <Button variant="#fff" size="md">
                {filteredData.length} courses found
              </Button>
            </Col>
            <Col>
              <Button className="btn-bg-color" variant="#fff" size="md">
                Generative Artificial intelligence X
              </Button>
            </Col>
            <Col>
              <Button className="btn-bg-color" variant="#fff" size="md">
                101 Certificate X
              </Button>
            </Col>
          </Row>

          <Row className="right-panel-middle-div">
            <Col className="middle-card-alignment">
              {filteredData.map((item) => (
                <BeLaunchifiedCard
                  key={item.id}
                  title={item.title}
                  link={item.link}
                  duration={item.duration}
                />
              ))}
            </Col>
          </Row>
        </Row>
      </Row>
    </Container>
  );
}

export default BeLaunchifiedComponent;
